@font-face {
  font-family: 'modular';
  src: url('./fonts/modular.otf');
}

.cover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

body {
  margin: 0;
  font-family: 'Helvetica Neue', sans-serif;
  background-image: url('./images/marble-bg.jpg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  color: white;
  
  -webkit-touch-callout: none; /* Safari Touch */
  -webkit-user-select: none;   /* Webkit */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Edge*/
   user-select: none;       /* Future-proof*/
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  outline: none;
  border: none;
  cursor: pointer;
}

.btn {
  padding: 8px 16px;
  font-size: 1.1em;
  font-family: 'modular';
}

.btn--primary {
  background-color: #40b2a3;
  color: white;
  border-radius: 4px;
}
.btn--block {
  width: 100%;
}

.input-row {
  margin: 10px auto;

  input {
    box-sizing: border-box;
    font-size: 1.2em;
    background: transparent;
    border: 2px solid white;
    border-radius: 4px;
    padding: 8px;
    color: white;
    outline: none;
    font-family: 'modular';
  }
}