.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 600px;
  height: 90%;
}
@media (max-width: 600px) {
  .wrapper {
    width: 100%;
    height: 80%;
  }
}

.gameWrapper {
  position: relative;
  margin: auto;
  display: flex;
  height: 90%;
  width: 100%;
  flex: 1 1 90%;
}

canvas {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
}




.status {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
  font-family: 'modular';
}