.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  min-height: 80px;
  flex: 1 0 80px;
  width: 100%;
  box-sizing: border-box;

  svg {
    pointer-events: none !important;
  }
}

.direction {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  
  [data-role="touch-control"]:nth-child(1) {
    margin-right: 20px;
    background-image: url('../../images/arrow-angle-left.png');
    background-size: 40px 40px;
    background-repeat: no-repeat;
    background-position: center;

    svg {
      margin-right: 10px;
    }
  }
  [data-role="touch-control"]:nth-child(2) {
    background-image: url('../../images/arrow-angle-right.png');
    background-size: 40px 40px;
    background-repeat: no-repeat;
    background-position: center;

    svg {
      margin-left: 10px;
    }
  }
}

.jump {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: grey;
}

[data-role="touch-control"] {
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 2px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255,255,255,0.2);

  svg {
    fill: white;
    height: 3em;
    pointer-events: none;
  }
}
[data-role="touch-control"]::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
}