@font-face {
  font-family: 'modular';
  src: url('../../fonts/modular.otf');
}

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.content {
  max-width: 500px;
}
@media (max-width: 800px) {
  .content {
    max-width: 95%;
  }
}

.welcomeTitle {
  max-width: 100%;
  font-size: 3em;
}
@media (max-width: 800px) {
  .welcomeTitle {
    font-size: 4.2em;
  }
}

.welcomeSubtitle {
  font-size: 2.5em;
  line-height: 1em;
  font-weight: 900;
}
@media (max-width: 800px) {
  .welcomeSubtitle {
    font-size: 1.9em;
  }
}

p {
  font-size: 1.2em;
}
@media (max-width: 800px) {
  p {
    font-size: 1.1em;
  }
}

.enterCta {
  position: relative;
}

.instructionStepWrap {
  position: relative;
  width: 350px;
  height: 200px;
}
@media (max-width: 800px) {
  .instructionStepWrap {
    width: 90vw;
  }
}

.instructionStep {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
  opacity: 0;
  font-size: 1.2em;
  font-family: 'modular';

  img {
    max-height: 250px;
  }
}
.instructionStep[data-active="true"] {
  opacity: 1;
}

.collectibles {
  img {
    margin: 20px;
  }
  img:nth-child(2) {
    height: 90px;
  }
}