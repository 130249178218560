@font-face {
  font-family: 'modular';
  src: url('../../fonts/modular.otf');
}

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  button {
    font-family: 'modular';
  }
}

.score {
  margin: 20px auto;
}

.header {
  font-family: 'modular';
  font-size: 3em;
}

.scoreNumber {
  font-family: 'modular';
  font-size: 4em;
}

.cta {
  display: flex;
  justify-content: center;

  button {
    margin: 0 10px;
  }
}

.close {
  font-size: 1.2em;
  font-family: 'modular';
  cursor: pointer;
  margin: 20px;
}