@font-face {
  font-family: 'modular';
  src: url('../../fonts/modular.otf');
}

.wrapper {
  font-family: 'modular';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../../images/marble-bg.jpg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  text-align: center;
  z-index: 1;
}

.content {
  max-width: 500px;
}

.close {
  font-size: 1.2em;
  font-family: 'modular';
  cursor: pointer;
  margin: 20px;
}

.tableWrapper {
  overflow-y: scroll;
  height: 50vh;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  font-weight: 900;
  font-size: 1em;
  text-transform: uppercase;
}
@media (max-width: 500px) {
  .leaderboardWrapper {
    max-width: 90%;
  }
}

.table {
  width: 100%;
  font-size: 1.2em;
  border-spacing: 0;
  border-collapse: collapse;
  table-layout: fixed;

  td  {
    padding: 6px 0;
    text-align: left;
  }
  td:first-child {
    padding: 0;
    width: 70%;
  }
  td:last-child {
    text-align: right;
  }

  .highScoreNumber {
    margin-right: 0px;
  }
}