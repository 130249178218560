.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  color: white;
  font-size: 1.2em;
  z-index: 1;
}

@media (max-width: 800px) {
  .wrapper {
    padding: 15px;
  }
}

.title {
  position: absolute;
  top: 20px;
  left: 20px;
  margin: auto;
  text-align: center;
  width: 100px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 1.4em;
  z-index: 1;
}


@media (max-width: 800px) {
  .title {
    top: 15px;
    width: 100px;
    display: none;
  }
}

.socials {
  svg {
    font-size: 1em;
    margin-right: 10px;
  }
}

.navTrigger {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 30px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer !important;
  z-index: 1;
}

.iconCenter {
  width: 100%;
  height: 2px;
  opacity: 1;
  background-color: white;
  transition: all 300ms ease;
}

.iconAnimate::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  transform-origin: top left;
  background-color: white;
  transition: all 300ms ease;
}
.iconAnimate::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  transform-origin: bottom left;
  background-color: white;
  transition: all 300ms ease;
}

.navTrigger[data-open="true"] .iconCenter {
  opacity: 0;
  transition: all 300ms ease;
}
.navTrigger[data-open="true"] .iconAnimate::before {
  top: -3px;
  transform: rotate(45deg) ;
  background-color: white;
  transition: transform 300ms ease, top 300ms ease;
}
.navTrigger[data-open="true"] .iconAnimate::after {
  bottom: -3px;
  transform: rotate(-45deg);
  background-color: white;
  transition: transform 300ms ease, bottom 300ms ease;
}