.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  z-index: -1;
  transition: opacity 800ms cubic-bezier(0,.58,.6,.99), z-index 400ms 400ms;
  background-color: black;
  color: white;

  a {
    color: white;
  }
}

.wrapper[data-active="true"] {
  opacity: 1;
  z-index: 1;
  transition: opacity 800ms cubic-bezier(0,.58,.6,.99);
}

.content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin: auto;
  padding: 0;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

.label {
  font-weight: 100;
  letter-spacing: 4px;
  font-weight: 800;
  font-size: 0.8em;
  cursor: pointer;
}

.column {
  width: 50%;
}
@media (max-width: 800px) {
  .column {
    width: 100%;
  }
}

.nav{
  width: 50%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 1.6em;

  li {
    margin: 20px 0;
    box-sizing: border-box;
  }
}
@media (max-width: 800px) {
  .nav {
    width: 100%;
  }
}

.socials {
  svg {
    height: 20px;
    width: 20px;
    display: inline-block;
    margin: 0 5px;
    padding: 0;
    vertical-align: middle;
    fill: white;
  }

  ul {
    width: 400px;
    margin: auto;

    li {
      display: inline-block;
      margin: 10px 10px 10px 0;
      vertical-align: middle;
    }
  }
}

@media (max-width: 800px) {
  .socials ul {
    width: 95%;
    margin-bottom: 20px;
  }
}

.album {
  width: 50%;
  text-align: center;
  font-weight: 900;
}
.albumArt {
  margin: 0 auto;
  max-width: 55%;
  margin-bottom: 20px;
  box-sizing: border-box;

  

  img {
    max-width: 100%;
    margin-bottom: 20px;
  }

  a {
    width: 47%;
    font-size: 1.4em;
    display: block;
    margin: 8px auto;
  }
}



@media (max-width: 800px) {
  .album {
    width: 100%;

    a {
      width: 95%;
      box-sizing: border-box;
    }
  }

  .albumArt {
    max-width: 50%;

    img {
      margin-bottom: 10px;
    }
  }
}

@media (max-width: 600px) {
  .album {
    width: 100%;
  }

  .albumArt {
    max-width: 80%;
  }
}

